<template>
    <div class="tab-section">
        <div class="tab-pane">
            <div class="tab-content loading" v-for="item in 3">                
            </div>
        </div>
        <GridLayoutPlaceholder></GridLayoutPlaceholder>
    </div>
</template>
<script>
export default {
    components: {    
        GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
    }
}
</script>
<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.tab-section{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: clamp(2rem, 100%, 98%);
    margin: auto;
    .tab-pane {
        padding: 0 2rem;
        width: 100%;
        height: 35px;
        display: flex;
        gap: 0.5rem;
        border-bottom: 2px solid #929292;
        .tab-content {
            width: 150px;
            height: 30px;
            background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
            border-radius: 8px;
            overflow: hidden;
            border-radius: 4px;
        }
    }
}
</style>